'use client';

import * as React from 'react';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import AuthLayout from 'src/components/layout/auth';
import {
  Anchor,
  Box,
  Button,
  Flex,
  Form,
  PasswordInput,
  Stack,
  Text,
  TextInput
} from '@company/ui/components';
import { useZodForm } from '@company/ui/hooks/form';
import { signInAction } from './actions';
import { SignInData, signInSchema } from './schemas';

export default function SignInPage() {
  const { _ } = useLingui();
  const form = useZodForm({
    schema: signInSchema,
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = async (data: SignInData) => {
    const result = await signInAction(data);
    if (result?.errorMessage) {
      form.setError('root', { message: result.errorMessage });
    }
  };

  return (
    <AuthLayout
      title={_(msg`Sign In`)}
      description={_(msg`Enter your email below to login to your account`)}
    >
      <Box w={'full'}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack gap={4} w={'full'}>
            <TextInput
              isRequired
              label={_(msg`Email`)}
              placeholder={_(msg`your@email.com`)}
              isDisabled={form.formState.isSubmitting}
              form={form}
              name={'email'}
            />
            <PasswordInput
              label={
                <Flex justify={'space-between'} w={'100%'}>
                  <Box>
                    <Trans>Password</Trans>
                  </Box>
                  <Box>
                    <Anchor href="/forgot-password" fontSize={'sm'}>
                      <Trans>Forgot password?</Trans>
                    </Anchor>
                  </Box>
                </Flex>
              }
              placeholder={_(msg`Password`)}
              isDisabled={form.formState.isSubmitting}
              form={form}
              name={'password'}
            />
            {form.formState.errors.root?.message && (
              <Text fontSize={'sm'} color={'red.500'}>
                {form.formState.errors.root.message.toString()}
              </Text>
            )}
            <Button
              type="submit"
              w={'full'}
              colorScheme={'primary'}
              loading={form.formState.isSubmitting}
              disabled={form.formState.isSubmitting}
              colorPalette={'primary'}
            >
              <Trans>Login</Trans>
            </Button>
          </Stack>
        </Form>
      </Box>
    </AuthLayout>
  );
}
